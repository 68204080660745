@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin button($theme) {

  $config: mat-get-color-config($theme);

  $primary: map-get($config, primary);
  $primary-contrast: map-get($primary, contrast);

  .mat-button-focus-overlay {
    background-color: transparent !important;
  }

  [mat-raised-button],
  [mat-stroked-button] {
    .mat-button-wrapper {
      display: flex;
      align-items: center;

      .mat-button-icon {
        &_left {
          margin-right: 10px;
        }

        &_right {
          margin-left: 10px;
        }
      }
    }
  }

  [mat-raised-button] {
    &.button-primary-filled {
      border-radius: 8px;
      color: map-get($primary-contrast, 500);
      height: map-get($button-primary-filled, "height");
      font-weight: map-get($button-primary-filled, "font-weight");
      font-size: map-get($button-primary-filled, "font-size");
      line-height: map-get($button-primary-filled, "line-height");
      padding: 0 22px 0 22px;
      box-shadow: none;
      transition-property: box-shadow, background;

      mat-icon {
        path {
          fill: map-get($primary-contrast, 500);
        }
      }

      &.mat-primary {
        background: mat-color($primary, 500);
      }

      &.mat-warning {
        background: $gs-warning-500;
      }

      &.mat-success {
        background: $gs-success-500;
      }

      &:hover:not([disabled]) {
        &.mat-primary {
          background: mat-color($primary, 600);
          box-shadow: 0 4px 8px rgba($gs-primary-500, 0.32);
        }

        &.mat-warning {
          background: $gs-warning-600;
          box-shadow: 0 4px 8px rgba($gs-warning-500, 0.32);
        }

        &.mat-success {
          background: $gs-success-600;
          box-shadow: 0 4px 8px rgba($gs-success-500, 0.32);
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          background: mat-color($primary, 700);
        }

        &.mat-warning {
          background: $gs-warning-700;
        }

        &.mat-success {
          background: $gs-success-700;
        }

        box-shadow: none !important;
      }

      &[disabled] {
        opacity: 0.5;
        color: map-get($primary-contrast, 500);
      }

      &.button-primary-filled-secondary {
        height: map-get($button-primary-filled-secondary, "height");
        padding: 0 16px;
      }

      &.button-primary-filled-with-icon {
        &.button-primary-filled-secondary {
          padding: 0 16px 0 12px;

          mat-icon {
            margin-right: 8px;
          }
        }
      }
    }
  }

  [mat-stroked-button] {
    &.button-primary-outline {
      border-radius: 8px;
      height: map-get($button-primary-outline, "height");
      font-weight: map-get($button-primary-outline, "font-weight");
      font-size: map-get($button-primary-outline, "font-size");
      line-height: map-get($button-primary-outline, "line-height");
      padding: 0 22px 0 22px;
      box-shadow: none;
      transition-property: box-shadow, color, border;
      transition: border 280ms cubic-bezier(0.25, 0.8, 0.25, 1), color 280ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      background: map-get($primary-contrast, 500);

      &.mat-primary {
        border-color: mat-color($primary, 500);
        color: mat-color($primary, 500);

        mat-icon {
          path {
            fill: mat-color($primary, 500);
            transition-property: fill;
            transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          }
        }
      }

      &:hover:not([disabled]) {
        &.mat-primary {
          border-color: mat-color($primary, 600);
          color: mat-color($primary, 600);
          box-shadow: 0 4px 8px rgba($gs-primary-500, 0.32);

          mat-icon {
            path {
              fill: mat-color($primary, 600);
            }
          }
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          border-color: mat-color($primary, 700);
          color: mat-color($primary, 700);

          mat-icon {
            path {
              fill: mat-color($primary, 700);
            }
          }
        }

        box-shadow: none !important;
      }

      &[disabled] {
        opacity: 0.5;

        &.mat-primary {
          color: mat-color($primary, 500);
        }
      }
    }

    &.button-primary-basic-shadow {
      border-radius: 50px;
      height: map-get($button-primary-basic-shadow, "height");
      font-weight: map-get($button-primary-basic-shadow, "font-weight");
      font-size: map-get($button-primary-basic-shadow, "font-size");
      line-height: map-get($button-primary-basic-shadow, "line-height");
      padding: 0 16px 0 16px;
      box-shadow: 0 2px 16px rgba($gs-primary-500, 0.16);
      color: $gs-text-second;
      transition-property: box-shadow, color, border;
      transition: border 280ms cubic-bezier(0.25, 0.8, 0.25, 1), color 280ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      background: map-get($primary-contrast, 500);
      border: 1px solid map-get($primary-contrast, 500);

      &:hover:not([disabled]) {
        &.mat-primary {
          border: 1px solid mat-color($primary, 500);
          color: mat-color($primary, 500);
          box-shadow: 0 4px 8px rgba($gs-primary-500, 0.32);
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          border: 1px solid mat-color($primary, 700);
          color: mat-color($primary, 700);
        }

        box-shadow: none !important;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

  [mat-button] {
    &.button-primary-basic-filled {
      border-radius: 50px;
      height: map-get($button-primary-basic-filled, "height");
      font-weight: map-get($button-primary-basic-filled, "font-weight");
      font-size: map-get($button-primary-basic-filled, "font-size");
      line-height: map-get($button-primary-basic-filled, "line-height");
      padding: 0 16px 0 16px;
      transition-property: background;
      transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

      &.mat-primary {
        color: map-get($primary, 500);
        background: rgba(map-get($primary, 500), 0.1);
      }

      &:hover:not([disabled]) {
        &.mat-primary {
          background: rgba(map-get($primary, 500), 0.2);
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          background: rgba(map-get($primary, 500), 0.3);
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    &.button-primary-basic-text {
      border-radius: 50px;
      height: map-get($button-primary-basic-filled, "height");
      font-weight: map-get($button-primary-basic-filled, "font-weight");
      font-size: map-get($button-primary-basic-filled, "font-size");
      line-height: map-get($button-primary-basic-filled, "line-height");
      padding: 0 16px 0 16px;
      transition-property: color;
      transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
      color: $gs-text-second;

      &:hover:not([disabled]) {
        &.mat-primary {
          color: map-get($primary, 500);
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          color: map-get($primary, 700);
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

  [mat-icon-button] {

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.button-icon-outline {
      width: map-get($button-icon-outline, "size");
      height: map-get($button-icon-outline, "size");
      border-radius: 50%;
      border: 1px solid $gs-text-third;
      box-shadow: 0 2px 16px rgba($gs-text-second, 0.12);
      transition-property: border-color, box-shadow;
      transition: border-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

      mat-icon {
        path {
          transition-property: fill;
          transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          fill: $gs-text-third;
        }
      }

      &:hover:not([disabled]) {
        &.mat-primary {
          border-color: map-get($primary, 500);
          box-shadow: 0 2px 16px rgba($gs-primary-500, 0.16);

          mat-icon {
            path {
              fill: map-get($primary, 500);
            }
          }
        }
      }

      &:active:not([disabled]) {
        &.mat-primary {
          border-color: map-get($primary, 700);
          box-shadow: 0 2px 16px rgba($gs-primary-500, 0.16);

          mat-icon {
            path {
              fill: map-get($primary, 700);
            }
          }
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }

    &.button-icon {
      width: map-get($button-icon, "size");
      height: map-get($button-icon, "size");
      border-radius: 50%;
      transition-property: background;
      transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

      mat-icon {
        path {
          transition-property: fill;
          transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          fill: $gs-text-third;
        }
      }

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background: $gs-neutral-light-gray;

        &.mat-primary {
          mat-icon {
            path {
              fill: map-get($primary, 500);
            }
          }
        }
      }

      &:active:not([disabled]) {
        background: $gs-neutral-light-gray;

        &.mat-primary {
          mat-icon {
            path {
              fill: map-get($primary, 700);
            }
          }
        }
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }

  .button-group {
    button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
