@import "ui-kit";

.dropdown-panel-advanced-fixed-220 {
  width: 220px;
  min-width: unset !important;
  &.ng-animating {
    display: none;
  }
}

.dropdown-panel-advanced {
  .simplebar-track.simplebar-vertical {
    background: transparent;
    width: 8px;

    .simplebar-scrollbar {
      right: 0;
    }
  }
}

.template-editor-node {
    border: 1px solid silver;
    border-radius: 4px;

    &_header {
        padding-left: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        min-height: 30px;
        flex-shrink: 0;
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;

        i {
            width: 20px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &.draggable {
                width: 9px;

                svg {
                    path {
                        fill: gray;
                    }
                }
            }
        }

        span {
            flex: 1;
            word-break: break-word;
        }

        .remove {
            flex-shrink: 0;
            background: none;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            width: 30px;

            &:hover {
                svg {
                    path {
                        fill: $gs-primary-500;
                    }
                }
            }
        }
    }

    &.active {
        border-color: $gs-primary-500;
    }

    &.cdk-drag {
        background-color: #F4F7F9;
    }
}
