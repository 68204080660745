@import '~@angular/material/theming';

@mixin svgSize($name, $width, $height) {
  &[data-mat-icon-name=#{$name}],
  &[svgicon=#{$name}] {
    svg {
      width: $width;
      height: $height;
    }
  }
}

@mixin icon($theme) {
  mat-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;

    @include svgSize('pencil', 18px, 18px);
    @include svgSize('times', 14px, 14px);
    @include svgSize('times-filled', 16px, 16px);
    @include svgSize('search', 18px, 18px);
    @include svgSize('dropdown-arrow', 12px, 8px);
    @include svgSize('plus', 14px, 14px);
    @include svgSize('filter-arrow', 10px, 5px);
    @include svgSize('arrow', 12px,  7px);
    @include svgSize('copy', 19px, 22px);
    @include svgSize('basket', 14px, 18px);
    @include svgSize('sidenav-arrow', 6px, 10px);
    @include svgSize('nav-statistics', 22px, 12px);
    @include svgSize('nav-database', 16px, 20px);
    @include svgSize('nav-subscriptions', 22px, 24px);
    @include svgSize('nav-events', 15px, 17px);
    @include svgSize('nav-rate', 20px, 16px);
    @include svgSize('nav-orders', 22px, 14px);
    @include svgSize('nav-report', 18px, 18px);
    @include svgSize('logout', 18px, 19px);
    @include svgSize('info', 18px, 18px);
    @include svgSize('bell', 16px, 20px);
    @include svgSize('pagination-first', 15px, 14px);
    @include svgSize('pagination-last', 15px, 14px);
    @include svgSize('pagination-prev', 8px, 14px);
    @include svgSize('pagination-next', 8px, 14px);
    @include svgSize('location', 16px, 22px);
    @include svgSize('more-vertical', 4px, 18px);
    @include svgSize('time', 12px, 12px);
    @include svgSize('gear', 18px, 18px);
    @include svgSize('flag', 22px, 24px);
    @include svgSize('calendar', 16px, 16px);
    @include svgSize('location-mini', 12px, 16px);
    @include svgSize('seat', 15px, 16px);
    @include svgSize('ticket', 32px, 20px);
    @include svgSize('checkmark-filled', 60px, 60px);
    @include svgSize('hourglass', 15px, 20px);
    @include svgSize('warning-filled', 20px, 19px);
    @include svgSize('lock-unlocked', 42px, 60px);
    @include svgSize('lock-locked', 42px, 60px);
    @include svgSize('error', 17px, 15px);
    @include svgSize('chair', 32px, 45px);
    @include svgSize('checkmark', 18px, 14px);
    @include svgSize('refresh', 20px, 20px);
    @include svgSize('save', 20px, 20px);
    @include svgSize('event-calendar', 17px, 18px);
    @include svgSize('price-rates', 21px, 21px);
    @include svgSize('ticket-layouts', 24px, 16px);
    @include svgSize('season-ticket-consist', 20px, 20px);
    @include svgSize('open-sale', 15px, 20px);
    @include svgSize('sales-restrictions', 20px, 20px);
    @include svgSize('view-sales', 20px, 14px);
    @include svgSize('minus', 14px, 2px);
    @include svgSize('download', 28px, 25px);
  }
}
