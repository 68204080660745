@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin color-picker($theme) {

  .input-color-select {
    .mat-select-value {
      max-width: 100%;
    }
    .current-color {
      display: flex;
      align-items: center;
      .current-color-indicator {
        min-width: 16px;
        flex-grow: 0;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }

  .color-picker-panel {
    width: 224px;
    height: 333px;
    min-width: 224px !important;
    min-height: 333px;
    overflow: hidden !important;

    $dropdown-panel-padding: map-get($form-field, "dropdown-panel-padding");
    margin-top: 38px;
    box-shadow: 0 2px 16px rgba($gs-text-second, 0.12) !important;
    border-radius: 8px !important;

    mat-option {
      display: none;
    }
    .pickr {
      display: none;
    }
    .pcr-app {
      height: 100%;
      width: 100%;
      .pcr-selection {
        order: 1;
        height: 256px;
        grid-row-gap: 10px;
        .pcr-color-chooser {
          grid-column-start: 1;
          grid-column-end: 3;
          margin: 0 20px;
          height: 16px;
        }
      }
      .pcr-swatches {
        order: 3;
        grid-template-columns: repeat(auto-fit, 16px);
        grid-gap: 8px;
        margin-top: 0;
        margin-left: 8px;
        padding: 0;
        position: relative;
        button {
          width: 16px;
          height: 16px;
          padding: 0;
          margin: 0;
        }
        &:before {
          content: "Палитра цветов";
          position: absolute;
          top: -28px;
          font-family: 'Gilroy', serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.1px;
        }
      }
      .pcr-interaction {
        order: 2;
        justify-content: flex-end;
        .pcr-result {
          width: 70px;
          min-width: 70px;
          margin: 0;
          flex: unset;
        }
      }
    }
  }
}
