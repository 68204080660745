@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin tab($theme) {
  mat-tab-group {
    &.hide-header {
      mat-tab-header {
        display: none;
      }
    }
    .mat-tab-list {
      flex-grow: unset;
    }
    .mat-tab-header {
      border-bottom-color: $gs-neutral-medium-gray;
    }
    .mat-ink-bar {
      height: 3px;
    }
    .mat-tab-label {
      font-size: map-get($tab, "font-size");
      line-height: 24px;
      font-weight: 500;
      opacity: 1;
      color: $gs-text-second;
      height: map-get($tab, "height");
      padding: 0 16px;
      min-width: unset;
      &.mat-tab-label-active {
        color: $gs-text-basic;
      }
      &:hover {
        color: $gs-text-basic;
      }
    }
  }
}
