@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin icon-button-menu($theme) {
  .mat-menu-panel {
    &.icon-button-menu-popup-panel {
      box-shadow: none;
      overflow: initial;
      border-radius: 4px !important;
      background: $gs-primary-500-contrast;
      margin-top: 4px;
      min-width: 210px;

      .icon-button-menu-panel-container {
        box-shadow: 0 2px 16px rgba($gs-text-second, 0.12) !important;
        border-radius: 4px;
      }

      .mat-menu-content:not(:empty) {
        padding: 0;
      }

      .mat-list {
        padding: 20px 0;

        .mat-list-item {
          font-size: map-get($icon-button-menu, "font-size");
          min-height: 16px;
          height: unset;
          color: $gs-text-basic;
          word-break: break-word;

          transition-property: box-shadow;
          transition: box-shadow 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          &:hover {
            box-shadow: 0 2px 16px rgba(80, 111, 133, 0.12);
          }
          .mat-list-item-with-icon {
            min-height: 24px;
            display: flex;
            align-items: center;
            width: 100%;

            mat-icon {
              margin-right: 16px;
            }
          }
        }
      }

      .mat-list-item-content {
        padding: 8px 16px !important;
        line-height: 16px;
        cursor: pointer;
      }
    }
    .simplebar-track.simplebar-vertical {
      background: transparent;
      width: 8px;

      .simplebar-scrollbar {
        right: 0;
      }
    }
  }
}
