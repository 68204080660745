
@font-face {
    font-family: "COUR_54";
    src: url("54-cour.ttf") format("opentype");
}

@font-face {
    font-family: "SCB_55";
    src: url("55-scb.ttf") format("opentype");
}

@font-face {
    font-family: "ARICYR_56";
    src: url("56-aricyr.ttf") format("opentype");
}

@font-face {
    font-family: "ARIAL";
    src: url("Arial.ttf") format("opentype");
}

@font-face {
    font-family: "ARIAL_RUS";
    src: url("Arial-Rus.ttf") format("opentype");
}

@font-face {
    font-family: "COUR";
    src: url("cour.ttf") format("opentype");
}

@font-face {
    font-family: "COURIER";
    src: url("Courier.ttf") format("opentype");
}

@font-face {
    font-family: "COURIER_RUS";
    src: url("Courier-Rus.ttf") format("opentype");
}

@font-face {
    font-family: "SCHOOL_BOOK_C";
    src: url("SchoolBookC.ttf") format("opentype");
}

@font-face {
    font-family: "SCHOOL_BOOK_C_RUS";
    src: url("SchoolBookC-Rus.ttf") format("opentype");
}

@font-face {
    font-family: "TIMES";
    src: url("Times.ttf") format("opentype");
}
