@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin sidenav($theme) {

  mat-sidenav-container {
    .mat-drawer {
      background: $gs-white;
    }

    &.sidenav-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $gs-background;

      mat-sidenav {
        width: 240px;
        visibility: visible !important;
        border-right: none;
        overflow: visible;
        transform: translate3d(calc(-100% - 1px), 0, 0);

        .sidenav-wrapper {
          margin-top: 24px;
        }

        .sidenav-control {
          position: absolute;
          top: 0;
          right: -32px;
          padding-top: 12px;
          padding-left: 8px;
          bottom: 0;
          cursor: pointer;
          transition-property: right;
          transition: right 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

          button {
            width: 24px;
            height: 24px;
            z-index: 1;
            background: #fff;
            border: none;
            box-shadow: 0 0 8px rgba(17, 47, 83, 0.24);
            transition: none;

            mat-icon {
              height: 22px;
              line-height: 22px;
              transition-property: transform;
              transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
              transform: rotateZ(180deg);

              path {
                transition: none;
              }
            }
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 12px;
            bottom: 0;
            width: 1px;
            background: $gs-neutral-medium-gray;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            bottom: 0;
            background: $gs-white;
          }
        }

        &:not(.mat-drawer-opened) {
          .sidenav-control {
            &:hover {
              button {
                background: $gs-primary-500 !important;
                mat-icon {
                  path {
                    fill: $gs-primary-500-contrast !important;
                  }
                }
              }
              &:after {
                background: $gs-primary-500;
              }
            }
          }
        }

        &.mat-drawer-opened {
          .sidenav-control {
            right: -12px;
            cursor: default;
            button {
              mat-icon {
                transform: rotateZ(0);
              }
              &:hover {
                background: $gs-primary-500 !important;
                mat-icon {
                  path {
                    fill: $gs-primary-500-contrast !important;
                  }
                }
              }
            }
            &:after {
              background: unset !important;
            }
            &:before {
              display: none;
            }
          }

          &:after {
            right: 0;
            opacity: 0;
          }
        }

        mat-tree {
          background: unset;
        }
        mat-tree-node {
          min-height: 40px;
          color: $gs-text-basic;
          letter-spacing: 0.25px;
          display: flex;
          padding: 0 16px 0 24px;
          align-items: center;
          cursor: pointer;
          user-select: none;
          overflow: hidden;
          position: relative;
          font-size: map-get($sidenav, "font-size");

          &.mat-tree-node-expanded {
            mat-icon[svgicon="arrow"] {
              transform: rotateZ(180deg);
            }
          }

          .nav-icon-wrapper {
            width: 40px;
          }

          &[aria-level="2"] + [aria-level="1"] {
            margin-top: 8px;
          }

          .nav-title {
            flex: 1;
          }

          &:hover {
            background: $gs-background;
            color: $gs-primary-500;
          }

          &.nav-active {
            background: $gs-background;
            color: $gs-primary-500;
            &:after {
              content: '';
              position: absolute;
              right: -5px;
              top: 0;
              bottom: 0;
              width: 10px;
              border-radius: 15px;
              background: linear-gradient(180deg, #65AEFF 0%, #3991F2 100%);
            }
          }
        }
      }

      mat-sidenav-content {
        margin-left: 20px;
      }
    }
  }
}
