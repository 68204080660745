@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin pagination($theme) {
  .pagination-wrapper {
    display: flex;
    align-items: center;
    .pagination-label {
      font-size: map-get($pagination, "font-size");
      color: $gs-text-third;
      line-height: 18px;
      letter-spacing: 0.25px;
      font-weight: normal;

      &.pagination-label-bold {
        font-weight: map-get($pagination, "font-weight");
        letter-spacing: 0.1px;
        line-height: 20px;
        margin-left: 5px;
      }
    }

    .pagination-actions {
      margin-left: 20px;

      button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
