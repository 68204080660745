@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin label($theme) {
  label {
    cursor: inherit;
    .label {
      height: map-get($label, "height");
      padding: 6px 8px;
      display: inline-flex;
      border-radius: 8px;
      align-items: center;
      box-sizing: border-box;
      .label-indicator {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .label-placeholder {
        letter-spacing: 0.1px;
        font-size: map-get($label, "font-size");
        line-height: 20px;
      }

      &.label-basic {
        background: $gs-label-background-basic;
        .label-indicator {
          background: $gs-label-color-basic;
        }
        .label-placeholder {
          color: $gs-label-color-basic;
        }
      }
      &.label-success {
        background: $gs-label-background-success;
        .label-indicator {
          background: $gs-label-color-success;
        }
        .label-placeholder {
          color: $gs-label-color-success;
        }
      }
      &.label-warning {
        background: $gs-label-background-warning;
        .label-indicator {
          background: $gs-label-color-warning;
        }
        .label-placeholder {
          color: $gs-label-color-warning;
        }
      }
      &.label-danger {
        background: $gs-label-background-danger;
        .label-indicator {
          background: $gs-label-color-danger;
        }
        .label-placeholder {
          color: $gs-label-color-danger;
        }
      }
      &.label-info {
        background: rgba($gs-primary-500, 0.1);
        .label-indicator {
          background: $gs-primary-500;
        }
        .label-placeholder {
          color: $gs-primary-500;
        }
      }

      &.label-disabled {
        opacity: 0.5;
      }
    }
  }
}
