@import '~@angular/material/theming';
@import "~src/palette";

@mixin button-menu($theme) {

  [mat-button] {
    &.button-menu-control {
      min-height: 32px;
      border-radius: 4px;
      color: $gs-text-basic;
      font-size: 14px;
      transition-property: background-color;
      transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
      text-align: left;

      &[disabled] {
        pointer-events: none;
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 14px;
        word-break: break-word;
        white-space: pre-line;
      }

      mat-icon {
        margin-left: 7px;
        transition-property: transform;
        transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        flex-basis: 0;
        path {
          transition-property: fill;
          transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }

      &:hover {
        background-color: rgba($gs-text-second, 0.04);
      }

      &.button-menu-opened {
        background-color: rgba($gs-text-second, 0.04);

        mat-icon {
          transform: rotateZ(180deg);
          path {
            fill: $gs-primary-500;
          }
        }
      }
    }
  }

  .mat-menu-panel {
    &.button-menu-panel {
      box-shadow: none;
      overflow: initial;
      border-radius: 4px !important;
      background: $gs-primary-500-contrast;
      min-height: initial;
      max-width: initial;

      .button-menu-panel-container {
        box-shadow: 0 2px 16px rgba($gs-text-second, 0.12) !important;
        border-radius: 4px;
      }

      .button-menu-list-container {
        max-height: 300px;
      }

      animation: none !important;
      transition: none !important;

      & * {
        animation: none !important;
        transition: none !important;
      }

      .mat-menu-content:not(:empty) {
        padding: 0;
      }

      .mat-list {
        padding: 8px 0;
        .mat-list-item {
          min-height: 16px;
          height: unset;
          color: $gs-text-basic;
          word-break: break-word;

          transition-property: background-color;
          transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          &:hover {
            background-color: $gs-neutral-light-gray;
          }
          &.mat-list-item-active {
            background-color: $gs-neutral-light-gray;
          }

          .mat-list-item-with-icon {
            min-height: 24px;
            display: flex;
            align-items: center;
            width: 100%;

            mat-icon {
              margin-right: 16px;
            }
          }
        }
      }

      .mat-list-item-content {
        padding: 8px 16px !important;
        line-height: 16px;
        cursor: pointer;
      }
    }
  }
}
