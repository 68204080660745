/* GS stands for GetSeat */

$gs-text-basic: #223345;
$gs-text-second: #506F85;
$gs-text-third: #839EB3;

$gs-neutral-dark-gray: #ACC0D0;
$gs-neutral-medium-gray: #CFDFE8;
$gs-neutral-light-gray: #ECF3F8;

$gs-white: #FFFFFF;

$gs-skeleton-bg: #f5f5f5;

$gs-sidebar-background: #112F53;
$gs-sidebar-background-hover: #153A65;

$gs-background: #F4F7F9;

$gs-primary-500: #3991F2;
$gs-primary-600: #097BF6;
$gs-primary-700: #2571D9;

$gs-primary-500-contrast: #FDFDFD;

$gs-success-500: #27AE60;
$gs-success-600: #49A17F;
$gs-success-700: #3A8474;

$gs-success-500-contrast: #FDFDFD;

$gs-danger-500: #ED584A;
$gs-danger-600: #ED584A;
$gs-danger-700: #ED584A;

$gs-danger-500-contrast: #FDFDFD;

$gs-warning-500: #FFB946;
$gs-warning-600: #F4A626;
$gs-warning-700: #E2920F;

$gs-warning-500-contrast: #FDFDFD;

$gs-checkbox: #a8b7c2;
$gs-checkbox-disabled: #dce2e7;

$gs-label-background-basic: #F8F8F8;
$gs-label-color-basic: #828282;

$gs-label-background-success: #ECFCF0;
$gs-label-color-success: #36AB50;

$gs-label-background-warning: #FFF6EE;
$gs-label-color-warning: #F2994A;

$gs-label-background-danger: #FDEEED;
$gs-label-color-danger: #ED584A;

$gs-primary: (
    50: purple,
    100: purple,
    200: purple,
    300: purple,
    400: purple,
    500: $gs-primary-500,
    600: $gs-primary-600,
    700: $gs-primary-700,
    800: purple,
    900: purple,
    A100: purple,
    A200: purple,
    A400: purple,
    A700: purple,
    contrast: (
        50: purple,
        100: purple,
        200: purple,
        300: purple,
        400: purple,
        500: $gs-primary-500-contrast,
        600: $gs-primary-500-contrast,
        700: $gs-primary-500-contrast,
        800: purple,
        900: purple,
        A100: purple,
        A200: purple,
        A400: purple,
        A700: purple,
    )
);

$gs-danger: (
    50: purple,
    100: purple,
    200: purple,
    300: purple,
    400: purple,
    500: $gs-danger-500,
    600: $gs-danger-600,
    700: $gs-danger-700,
    800: purple,
    900: purple,
    A100: purple,
    A200: purple,
    A400: purple,
    A700: purple,
    contrast: (
        50: purple,
        100: purple,
        200: purple,
        300: purple,
        400: purple,
        500: $gs-danger-500-contrast,
        600: $gs-danger-500-contrast,
        700: $gs-danger-500-contrast,
        800: purple,
        900: purple,
        A100: purple,
        A200: purple,
        A400: purple,
        A700: purple,
    )
);
