@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin input($theme) {
  $config: mat-get-color-config($theme);

  $primary: map-get($config, primary);
  $warn: map-get($config, warn);

  mat-form-field {
    .mat-input-element {
      caret-color: $gs-text-basic;
      color: $gs-text-basic;
    }

    &.input-search {
      min-width: 274px;
      background: $gs-primary-500-contrast;
      border: 1px solid $gs-neutral-medium-gray;
      border-radius: 8px;
      transition-property: border-color;
      transition: border-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

      &_not-empty,
      &.mat-focused {
        &.mat-primary {
          border-color: map-get($primary, 500);
        }
      }

      .mat-form-field-wrapper {
        padding: 0;
        height: 38px;
        display: flex;
        align-items: center;
      }

      .mat-form-field-flex {
        align-items: center;
      }

      .mat-form-field-infix {
        border: none;
        width: 170px;

        input {
          line-height: 24px;
        }

        .mat-form-field-label-wrapper {
          padding: 0;
          top: 0;
          display: flex;
          align-items: center;

          label {
            position: unset;
            color: $gs-text-third;
          }
        }
      }

      .mat-form-field-prefix {
        margin: 0 15px;
      }

      .mat-form-field-suffix {
        margin: 0 6px;
      }

      .mat-form-field-underline {
        display: none;
      }
    }

    &.input-select {
      mat-select {
        .mat-select-arrow {
          opacity: 0;
        }
      }

      &.mat-form-field-should-float,
      &.mat-focused {
        .mat-form-field-label-wrapper {
          label {
            mat-label {
              color: $gs-text-second;
            }
          }
        }
      }

      .mat-form-field-flex {
        align-items: center;
        padding: 8px 16px 0;
        height: map-get($form-field, "height");
        font-size: map-get($form-field, "font-size");
        border-radius: 4px;
        background: rgba($gs-text-second, 0.04);
        transition-property: background;
        transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

        .mat-form-field-infix {
          padding: 0;
          border: 0;

          mat-select {
            .mat-select-trigger {
              padding-top: 5px;

              .mat-select-value {
                color: $gs-text-basic;
                line-height: 24px;
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }

      &__opened {
        &.mat-primary {
          .mat-form-field-suffix {
            mat-icon {
              path {
                fill: map-get($primary, 500)
              }
            }
          }
        }

        .mat-form-field-suffix {
          transform: rotateZ(180deg);
        }

        &.mat-focused {
          .mat-form-field-flex {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &:hover {
        .mat-form-field-flex {
          background: rgba($gs-text-second, 0.06);
        }
      }

      .mat-form-field-label {
        width: 100%;
      }

      .mat-form-field-label-wrapper {
        label {
          mat-label {
            color: $gs-text-basic;
            line-height: 25px;
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-suffix {
        top: -4px;
        transition-property: transform;
        transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

        mat-icon {
          path {
            transition-property: fill;
            transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          }
        }
      }

      &__disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &.input-field {
      .mat-form-field-flex {
        align-items: center;
        padding: 16px 12px;
        height: map-get($form-field, "height");
        font-size: map-get($form-field, "font-size");
        line-height: map-get($form-field, "line-height");
        border-radius: 4px;
        background: rgba($gs-text-second, 0.04);
        transition-property: background;
        transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        cursor: text;

        .mat-form-field-infix {
          border: none;
          padding: 0;

          input {
            margin-top: 16px;
            letter-spacing: 0.25px;
            line-height: 125%;
          }

          .mat-form-field-label-wrapper {
            padding-top: 14px;
            top: -14px;

            .mat-form-field-label {
              color: $gs-text-third;
            }
          }
        }
      }

      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }

      &:hover {
        .mat-form-field-flex {
          background: rgba($gs-text-second, 0.06);
        }
      }

      .mat-form-field-underline {
        background: transparent;

        .mat-form-field-ripple {
          height: 2px !important;
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 12px;
        font-size: 12px;
        letter-spacing: 0.14px;
        line-height: 16px;
      }

      &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-8px) scale(.75);
        width: 133.33333%;
      }

      &.mat-form-field-can-float.mat-form-field-should-float {
        &.mat-primary {
          &:not(.mat-form-field-invalid) {
            .mat-form-field-flex {
              .mat-form-field-infix {
                .mat-form-field-label-wrapper {
                  .mat-form-field-label {
                    color: map-get($primary, 500);
                  }
                }
              }
            }
          }

          &.mat-form-field-invalid {
            .mat-form-field-flex {
              .mat-form-field-infix {
                .mat-form-field-label-wrapper {
                  .mat-form-field-label {
                    color: map-get($warn, 500);
                  }
                }
              }
            }
          }
        }
      }

      &.mat-form-field-disabled {
        opacity: 0.5;

        .mat-form-field-flex {
          cursor: default;
        }

        .mat-form-field-underline {
          display: none;
        }
      }
    }

    &.autocomplete-input {
      .mat-form-field-flex {
        align-items: center;
        padding: 16px 12px;
        height: map-get($form-field, "height");
        border-radius: 4px;
        background: rgba($gs-text-second, 0.04);
        transition-property: background;
        transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        cursor: text;

        .mat-form-field-infix {
          border: none;
          padding: 0;

          input {
            margin-top: 16px;
            letter-spacing: 0.25px;
            line-height: 125%;
          }

          .mat-form-field-label-wrapper {
            padding-top: 12px;
            top: -12px;

            .mat-form-field-label {
              color: $gs-text-third;
            }
          }
        }
      }

      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }

      &:hover {
        .mat-form-field-flex {
          background: rgba($gs-text-second, 0.06);
        }
      }

      .mat-form-field-underline {
        background: transparent;

        .mat-form-field-ripple {
          height: 2px !important;
        }
      }

      .mat-form-field-subscript-wrapper {
        padding: 0 12px;
        font-size: 12px;
        letter-spacing: 0.14px;
        line-height: 16px;
      }

      &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-8px) scale(.75);
        width: 133.33333%;
      }

      &.mat-form-field-can-float.mat-form-field-should-float {
        &.mat-primary {
          &:not(.mat-form-field-invalid) {
            .mat-form-field-flex {
              .mat-form-field-infix {
                .mat-form-field-label-wrapper {
                  .mat-form-field-label {
                    color: map-get($primary, 500);
                  }
                }
              }
            }
          }

          &.mat-form-field-invalid {
            .mat-form-field-flex {
              .mat-form-field-infix {
                .mat-form-field-label-wrapper {
                  .mat-form-field-label {
                    color: map-get($warn, 500);
                  }
                }
              }
            }
          }
        }
      }

      &.mat-form-field-disabled {
        opacity: 0.5;

        .mat-form-field-flex {
          cursor: default;
        }

        .mat-form-field-underline {
          display: none;
        }
      }

      &[floatlabel="never"] {
        .mat-form-field-infix input {
          height: 36px;
          margin-top: 0;
        }
      }

      .autocomplete-input-arrow {
        position: absolute;
        top: calc(50% - 12px);
        right: 0;
        transition-property: transform;
        transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

        svg {
          path {
            transition-property: fill;
            transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
          }
        }
      }

      input[aria-expanded="true"] + .autocomplete-input-arrow {
        transform: rotateZ(180deg);

        svg {
          path {
            fill: $gs-primary-500;
          }
        }
      }
    }

      &.input-select-client {
          width: 600px !important;
          margin-right: 0 !important;
          padding-top: 20px !important;

          mat-select {
              .mat-select-arrow {
                  opacity: 0;
              }
          }

          &.mat-form-field-should-float,
          &.mat-focused {
              .mat-form-field-label-wrapper {
                  label {
                      mat-label {
                          color: $gs-text-second;
                      }
                  }
              }
          }

          .mat-form-field-flex {
              align-items: center;
              padding: 8px 16px 0;
              height: map-get($form-field, "height");
              font-size: map-get($form-field, "font-size");
              border-radius: 4px;
              background: rgba($gs-text-second, 0.04);
              transition-property: background;
              transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

              .mat-form-field-infix {
                  padding: 0;
                  border: 0;

                  mat-select {
                      .mat-select-trigger {
                          padding-top: 5px;

                          .mat-select-value {
                              color: $gs-text-basic;
                              line-height: 24px;
                              letter-spacing: 0.25px;
                          }
                      }
                  }
              }
          }

          &__opened {
              &.mat-primary {
                  .mat-form-field-suffix {
                      mat-icon {
                          path {
                              fill: map-get($primary, 500)
                          }
                      }
                  }
              }

              .mat-form-field-suffix {
                  transform: rotateZ(180deg);
              }

              &.mat-focused {
                  .mat-form-field-flex {
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                  }
              }
          }

          &:hover {
              .mat-form-field-flex {
                  background: rgba($gs-text-second, 0.06);
              }
          }

          .mat-form-field-label-wrapper {
              label {
                  mat-label {
                      color: $gs-text-basic;
                      line-height: 25px;
                  }
              }
          }

          .mat-form-field-underline {
              display: none;
          }

          .mat-form-field-suffix {
              top: -4px;
              transition-property: transform;
              transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

              mat-icon {
                  path {
                      transition-property: fill;
                      transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
                  }
              }
          }

          &__disabled {
              pointer-events: none;
              opacity: 0.5;
          }
      }
  }

  mat-checkbox {
    font-size: map-get($checkbox, "font-size");
    &.input-checkbox {
      &.mat-checkbox-indeterminate,
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: $gs-checkbox;
        }
      }

      .mat-checkbox-label {
        color: $gs-text-basic;
        letter-spacing: 0.25px;
      }

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
        margin-right: 11px;

        .mat-checkbox-background,
        .mat-checkbox-frame {
          border-radius: 4px;
          border-width: 2px;
        }

        .mat-checkbox-frame {
          border-color: $gs-checkbox;
        }

        .mat-ripple-element {
          display: none;
        }
      }

      &.mat-checkbox-disabled {
        &.mat-checkbox-indeterminate,
        &.mat-checkbox-checked {
          .mat-checkbox-background {
            background-color: $gs-checkbox-disabled;
          }
        }

        .mat-checkbox-frame {
          border-color: $gs-checkbox-disabled;
        }

        .mat-checkbox-label {
          color: $gs-text-third;
        }
      }
    }
  }

  .dropdown-panel {
    $dropdown-panel-padding: map-get($form-field, "dropdown-panel-padding");
    margin-top: 38px;
    box-shadow: 0 2px 16px rgba($gs-text-second, 0.12) !important;
    width: calc(100% + #{$dropdown-panel-padding});
    border-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background: $gs-primary-500-contrast;

    &.autocomplete-dropdown-panel {
      margin-top: 0;
    }

    .mat-option {
      height: 40px !important;
      background: $gs-primary-500-contrast;
      font-size: 16px;
      line-height: 24px;
      color: $gs-text-basic;
      letter-spacing: 0.25px;

      &.mat-selected,
      &:hover {
        background: $gs-neutral-light-gray;
      }

      &.mat-selected {
        font-weight: 700;
        color: $gs-text-basic !important;
        background: $gs-neutral-light-gray !important;
      }
    }

    & > div {
      max-height: 256px;
    }

    [data-simplebar] {
      padding-bottom: 14px;

      .simplebar-content {
        padding-top: 8px;
      }

      .simplebar-track {
        &.vertical {
          .simplebar-scrollbar {
            &:before {
              bottom: 22px;
            }
          }
        }
      }
    }
  }

  .dropdown-panel-advanced {
    max-height: unset !important;

    & > .create-container {
      max-height: unset;
    }
  }
}
