@import '~@angular/material/theming';
@import '~simplebar/dist/simplebar.css';
@import "theme";
@import "palette";
@import "~src/shared/ui-kit/button/button";
@import "~src/shared/ui-kit/icon/icon";
@import "~src/shared/ui-kit/input/input";
@import "shared/ui-kit/label/label";
@import "shared/ui-kit/tab/tab";
@import "shared/ui-kit/filter/filter";
@import "shared/ui-kit/button-menu/button-menu";
@import "shared/ui-kit/icon-button-menu/icon-button-menu";
@import "shared/ui-kit/table/table";
@import "shared/ui-kit/sidenav/sidenav";
@import "shared/ui-kit/inline-spinner/inline-spinner";
@import "shared/ui-kit/pagination/pagination";
@import "shared/ui-kit/tooltip/tooltip";
@import "shared/ui-kit/directives/color-picker/color-picker";
@import "assets/fonts/editor-fonts.css";

@import '../node_modules/@simonwep/pickr/dist/themes/nano.min.css';

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("assets/fonts/gilroy-regular.ttf") format('truetype');
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("assets/fonts/gilroy-regular.ttf") format('opentype');
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("assets/fonts/Radomir Tinkov - Gilroy-Bold.otf") format('opentype');
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: url("assets/fonts/Radomir Tinkov - Gilroy-ExtraBold.otf") format('opentype');
}



$getseat-ui-kit-template-typography: mat-typography-config(
    $font-family: 'Gilroy'
);

@include mat-core($getseat-ui-kit-template-typography);

@include angular-material-theme($getseat-ui-kit-template-theme);

@include button($getseat-ui-kit-template-theme);
@include icon($getseat-ui-kit-template-theme);
@include input($getseat-ui-kit-template-theme);
@include label($getseat-ui-kit-template-theme);
@include tab($getseat-ui-kit-template-theme);
@include filter($getseat-ui-kit-template-theme);
@include button-menu($getseat-ui-kit-template-theme);
@include icon-button-menu($getseat-ui-kit-template-theme);
@include table($getseat-ui-kit-template-theme);
@include sidenav($getseat-ui-kit-template-theme);
@include inline-spinner($getseat-ui-kit-template-theme);
@include pagination($getseat-ui-kit-template-theme);
@include tooltip($getseat-ui-kit-template-theme);
@include color-picker($getseat-ui-kit-template-theme);

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Gilroy', sans-serif;
}

.headline-1 {
  font-size: map-get($typography-headline-1, "font-size");
  font-weight: map-get($typography-headline-1, "font-weight");
  line-height: map-get($typography-headline-1, "line-height");
  letter-spacing: map-get($typography-headline-1, "letter-spacing");
  color: $gs-text-basic;
}

.headline-2 {
  font-size: map-get($typography-headline-2, "font-size");
  font-weight: map-get($typography-headline-2, "font-weight");
  line-height: map-get($typography-headline-2, "line-height");
  letter-spacing: map-get($typography-headline-2, "letter-spacing");
  color: $gs-text-basic;
}

.headline-3 {
  font-size: map-get($typography-headline-3, "font-size");
  font-weight: map-get($typography-headline-3, "font-weight");
  line-height: map-get($typography-headline-3, "line-height");
  letter-spacing: map-get($typography-headline-3, "letter-spacing");
  color: $gs-text-basic;
}

.title {
  font-size: map-get($typography-title, "font-size");
  font-weight: map-get($typography-title, "font-weight");
  line-height: map-get($typography-title, "line-height");
  letter-spacing: map-get($typography-title, "letter-spacing");
  color: $gs-text-basic;
}

.body-text-1 {
  font-size: map-get($typography-body-text-1, "font-size");
  font-weight: map-get($typography-body-text-1, "font-weight");
  line-height: map-get($typography-body-text-1, "line-height");
  letter-spacing: map-get($typography-body-text-1, "letter-spacing");
  color: $gs-text-basic;
}

.body-text-2 {
  font-size: map-get($typography-body-text-2, "font-size");
  font-weight: map-get($typography-body-text-2, "font-weight");
  line-height: map-get($typography-body-text-2, "line-height");
  letter-spacing: map-get($typography-body-text-2, "letter-spacing");
  color: $gs-text-basic;
}

.body-text-3 {
  font-size: map-get($typography-body-text-3, "font-size");
  font-weight: map-get($typography-body-text-3, "font-weight");
  line-height: map-get($typography-body-text-3, "line-height");
  color: $gs-text-basic;
}

.text-bold {
  font-weight: 700;
}

.mat-select-panel {
  max-width: unset !important;
}

.mat-menu-panel {
  min-height: 0;
}

.fullscreen-mat-dialog-panel {
  max-width: 100vw !important;
  width: 100vw;
  height: 100vh;

  mat-dialog-container {
    padding: 0;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}

.fullscreen-dialog-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.primary-popup-panel {
  max-width: 100% !important;

  .mat-dialog-container {
    border-radius: 15px;
    background-color: $gs-primary-500-contrast;
    padding: 32px;
    position: relative;
  }

  .popup-close-container {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.primary-popup-backdrop {
  background-color: rgb(0 0 0 / 70%);
}

.default-popup-panel {
  .mat-dialog-container {
    padding: 0;
    border-radius: 30px;
  }
}

.simplebar-track {
  background: $gs-primary-500-contrast;
  border-radius: 15px;

  &.simplebar-vertical {
    width: 24px;

    .simplebar-scrollbar {
      right: 8px;
      width: 8px;

      &.simplebar-visible {
        &:before {
          background: $gs-neutral-medium-gray;
        }
      }

      &:before {
        transition-property: background;
        transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        opacity: 1;
        background: $gs-neutral-light-gray;
        top: 8px;
        bottom: 8px;
        border-radius: 10px;
      }
    }
  }

  &.simplebar-horizontal {
    height: 24px;

    .simplebar-scrollbar {
      top: 8px;
      height: 8px;

      &.simplebar-visible {
        &:before {
          background: $gs-neutral-medium-gray;
        }
      }

      &:before {
        transition-property: background;
        transition: background 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        opacity: 1;
        background: $gs-neutral-light-gray;
        left: 8px;
        right: 8px;
        border-radius: 10px;
      }
    }
  }
}

.card {
  display: flex;
  background: $gs-white;
  border-radius: 15px;
  padding: 16px;
}

.mat-snack-bar-container {
  padding: 0 !important;
  min-height: unset !important;
  border-radius: 10px !important;
  background: #fff;
  color: unset;
  box-shadow: 0 2px 16px rgba(80, 111, 133, 0.12);
}

@keyframes skeleton-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.input-date-time-picker-container,
.input-date-time-range-picker-container {
  .time-range {
    padding: 14px 8px 0 8px;
    display: flex;
    align-items: center;
  }

  .time-range-start {
    display: flex;
    flex-direction: column;
    width: 224px;
    margin-right: 32px;

    label {
      padding-left: 14px;
    }
  }

  .time-range-end {
    display: flex;
    flex-direction: column;
    width: 224px;
  }

  .inputs-container {
    display: flex;
    margin-top: 4px;

    input {
      height: 100%;
      width: 100%;
      max-width: initial;
      min-width: initial;
      padding: 0 12px;
      margin: 0;
      box-sizing: border-box;
      border: none;
      background: rgba($gs-text-second, 0.04);
      border-radius: 4px;
      outline: none;
      font-size: map-get($input-date-time-range-picker, "font-size");
      line-height: 24px;
      color: $gs-text-basic;
    }
  }

  .time-range-date-wrapper {
    width: 125px;
    height: 32px;
    margin-right: 20px;
  }

  .time-range-wrapper {
    width: 72px;
    height: 32px;
  }

  .error-container {
    padding: 16px 8px 0;
  }

  .actions-container {
    padding: 18px 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      height: 32px !important;
      font-weight: normal;

      &:first-of-type {
        margin-right: 8px;
      }
    }

    .button-primary-filled {
      border-radius: 20px;
    }
  }
}

.input-date-time-picker-container {
  .time-range-start {
    margin-right: 0;
  }
}
