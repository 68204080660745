@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin tooltip($theme) {
  .mat-tooltip {
    min-height: 38px;
    border-radius: 4px !important;
    box-shadow: 0 2px 16px rgba(80, 111, 133, 0.12);
    background: $gs-white;
    font-size: map-get($tooltip, "font-size");
    letter-spacing: 0.25px;
    color: $gs-text-second !important;
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-top: 0 !important;
    line-height: 20px;
  }
}
