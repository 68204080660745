@import '~@angular/material/theming';
@import "~src/palette";

@mixin table($theme) {

  mat-table {
    background: $gs-primary-500-contrast !important;
    border-radius: 15px;
    overflow-x: hidden;

    .simplebar-track.simplebar-vertical {
      top: 48px;
      bottom: 24px;
    }
  }

  .mat-header-cell, .mat-footer-cell, .mat-cell {
    min-width: 80px;
    box-sizing: border-box;
  }

  .mat-header-row, .mat-footer-row, .mat-row {
    min-width: 1920px; /* 24 columns, 80px each */
  }

  mat-header-row {
    background: $gs-primary-500-contrast !important;
    height: 47px;
    min-height: 47px !important;
    box-sizing: initial !important;
    border-bottom-color: $gs-neutral-light-gray;
  }

  .empty-scrollbar-row {
    min-height: 24px;
    height: 24px;
  }

  mat-row {
    border-bottom-color: $gs-neutral-light-gray;

    transition-property: box-shadow;
    transition: box-shadow 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 2px 16px rgba(80, 111, 133, 0.12);
      /*position: sticky;
      z-index: 101;*/
    }
  }

  mat-footer-row {
    border-bottom: none !important;
  }

  mat-header-cell,
  mat-cell {
    &.mat-table-sticky {
      height: 100%;
      background: linear-gradient(90deg, rgba(253, 253, 253, 0) 0%, rgba(253, 253, 253, 0.95) 28.65%, #FDFDFD 100%);
      &.mat-cell-sticky-start {
        background: linear-gradient(-90deg, rgba(253, 253, 253, 0) 0%, rgba(253, 253, 253, 0.95) 28.65%, #FDFDFD 100%);
      }
    }

    .mat-cell-content {
      padding-left: 16px;
    }
  }

  mat-header-cell,
  mat-cell,
  mat-footer-cell {
    &:first-of-type {
      padding-left: 0 !important;
    }
  }
}
