@import '~@angular/material/theming';
@import "~src/palette";
@import "~src/variables";

@mixin filter($theme) {

  [mat-stroked-button] {
    &.filter-button {
      border: 1px solid $gs-neutral-dark-gray;
      height: 32px;
      line-height: 30px;
      border-radius: 20px;
      color: $gs-text-second;
      font-size: map-get($filter, "button-font-size");
      background: $gs-primary-500-contrast;
      padding: 0 8px 0 15px;

      mat-icon[svgicon="filter-arrow"] {
        transition-property: transform;
        transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1);

        path {
          transition-property: fill;
          transition: fill 280ms cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }

      &.filter-button-opened {
        mat-icon[svgicon="filter-arrow"] {
          transform: rotateZ(180deg);

          path {
            fill: $gs-primary-500;
          }
        }
      }

      &.filter-button-selected {
        background: $gs-primary-500;
        border-color: $gs-white;
        color: $gs-white;

        mat-icon {
          path {
            fill: $gs-white !important;
          }

          &[svgicon="times"] {
            svg {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }

  .filter-panel {
    box-shadow: 0 2px 16px rgba($gs-text-second, 0.12) !important;
    width: calc(100% + 44px);
    border-radius: 15px !important;
    background: $gs-primary-500-contrast;
    margin-top: 4px;
    min-width: 300px !important;

    .mat-menu-content:not(:empty) {
      padding: 0;
    }

    .mat-list {
      padding-top: 0;
    }

    .mat-list-item-content {
      padding: 0 20px;
    }

    .filter-popup-header {
      display: flex;
      justify-content: space-between;
      padding: 18px 16px 0 16px;
      height: 34px;
      align-items: center;
      font-size: map-get($filter, "panel-font-size");
      line-height: 20px;
    }

    .filter-popup-body {
      padding: 8px 0;

      .mat-list {
        max-height: 300px;

        mat-list-item {
          cursor: pointer;
          min-height: 40px;
          height: initial;
		  display: flex;
        }
      }
    }

    .filter-popup-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px 16px;

      button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    mat-checkbox {
      &.input-checkbox {
        &.mat-checkbox-checked {
          .mat-checkbox-label {
            word-break: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

    .search-container {
        padding: 16px 16px 0;

        .input-search {
            min-width: unset;
        }
    }
}
